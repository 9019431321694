import _ from 'lodash';
import PrefetchHelper from '../utils/PrefetchHelper';
import TextHelper from '../utils/TextHelper';

const FUNC_ERROR_TEXT = 'Expected a function';
const buildGlobals = Symbol('buildGlobals');

export default class ApplicationGlobals {
  constructor(globalData) {
    const ns = 'LITHIUM';
    this.data = Object.freeze({
      globals: this[buildGlobals](_.get(globalData, [ns, 'CommunityJsonObject'], {})),
      prefetch: _.get(globalData, [ns, 'PrefetchData'], {}),
      text: _.get(globalData, [ns, 'TextData'], {})
    });
    this.globalObj = globalData.LITHIUM.Limuirs;
    this.globalObj.services = Object.freeze(this.globalObj.services || {});
  }

  /* eslint class-methods-use-this: "off" */
  [buildGlobals](community) {
    return Object.freeze({
      apiPath: _.get(community, ['Server', 'communityPrefix'], ''),
      tapestryPath: _.get(community, ['Server', 'tapestryPrefix'], ''),
      showTextKeys: _.get(community, ['Server', 'showTextKeys'], false),
      locale: _.get(community, ['User', 'settings', 'profile.language'], 'en'),
      communityId: _.get(community, ['id'], ''),
      rtl: _.get(community, ['Page', 'rtl'], true),
      userId: `${_.get(community, ['User', 'id'], -1)}`,
      feedback: _.get(community, ['Feedback'], null)
    });
  }

  get globals() {
    return this.data.globals;
  }

  getPrefetchViewForComponent({ alias, instance }) {
    return new PrefetchHelper(this.data.prefetch, alias, instance);
  }

  getTextViewForComponent(path) {
    return new TextHelper(this.data.text, path, this.globals.showTextKeys);
  }

  /**
   * Looks for an already-created service/store/model for the given name and creates one if one
   * has not already been created. This will store the service/store/model (and return the same object) for the
   * duration of the request.
   *
   * @param name the name of the service/store/model to get or create.
   * @param loader a loader function that will create the service/store/model if it's not found.
   * @returns {Object} the service/store/model
   */
  getService(name, loader) {
    if (!_.isFunction(loader)) {
      throw new TypeError(FUNC_ERROR_TEXT);
    }
    if (!Object.prototype.hasOwnProperty.call(this.globalObj.services, name)) {
      this.globalObj.services = Object.freeze(
        Object.assign({ [name]: loader() }, this.globalObj.services)
      );
    }
    return this.globalObj.services[name];
  }
}
