import { canUseDOM } from 'exenv';
import { log } from 'limuirs-utils';
import 'whatwg-fetch/fetch';

export default class RestManager {
  constructor(globals) {
    this.apiPath = globals ? globals.apiPath : '/';
  }

  request(method, shortRoute, body) {
    const url = this.getFullyQualifiedUrl(shortRoute);
    if (!RestManager.canCall()) {
      log.warn(
        'Unable to make a REST request from the current context: server-side or unsupported browser.'
      );
      return Promise.resolve({ data: {} });
    }
    return fetch(url, {
      credentials: 'same-origin',
      method,
      headers: new Headers({ 'Content-Type': 'application/json' }),
      body: body ? JSON.stringify(body) : null
    }).then(response => {
      if (response && response.status === 200) {
        return response.json();
      }
      throw response;
    });
  }

  static canCall() {
    return canUseDOM && !!window.fetch;
  }

  put(shortRoute, body = {}) {
    return this.request('PUT', shortRoute, body);
  }

  post(shortRoute, body = {}) {
    return this.request('POST', shortRoute, body);
  }

  delete(shortRoute, body = null) {
    return this.request('DELETE', shortRoute, body);
  }

  getFullyQualifiedUrl(apiUrl) {
    return `${this.apiPath}/api/2.0${apiUrl}`;
  }
}
