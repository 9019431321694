import _ from 'lodash';
import jref from 'lithium-json-ref-lite';
import { log } from 'limuirs-utils';
import RestManager from '../../services/rest/RestManager';

// See https://medium.com/@davidrhyswhite/private-members-in-es6-db1ccd6128a5
const unwrapData = Symbol('unwrapData');
const buildResult = Symbol('buildResult');
const buildCacheKey = Symbol('buildCacheKey');
const addQueryToFetchQueue = Symbol('addQueryToFetchQueue');
const fetchDataSuccess = Symbol('fetchDataSuccess');
const fetchDataFailure = Symbol('fetchDataFailure');
const dataLoadedPromises = Symbol('dataLoadedPromises');
const callFetchQueue = Symbol('callFetchQueue');
const data = Symbol('data');
const queryDataMap = Symbol('queryDataMap');

/**
 * Provides access to prefetch data for React components.
 */
export default class PrefetchHelper {
  constructor(prefetchData, alias, instance) {
    this[queryDataMap] = new Map();

    this.alias = alias;
    const resolvedData = jref.resolve(prefetchData);
    this[data] = _.get(resolvedData, ['Components', this.alias, 'instances', instance], null);
    this[callFetchQueue] = {};
    this[dataLoadedPromises] = {};

    if (this[data] === null) {
      log.trace(`No prefetch data for component [${this.alias}] and instance [${instance}]`);
    }
  }

  [buildCacheKey](queryId, call) {
    return `${queryId}::${JSON.stringify(call || {})}`;
  }

  /**
   * Unwrap data that use the following formats:
   *
   *<pre><code>
   *   data: {
   *     items: []
   *   }
   *
   *   response: {
   *     items: []
   *   }
   *
   *   data: {
   *     count: ...
   *   }
   *
   *   response: {
   *     count: ...
   *   }
   *</code></pre>
   *
   * @param {object} dataWrapper a wrapper object containing the data we're interested in
   * @param {string} queryId the queryId represented by the data
   * @returns {array|number|null} the unwrapped data, can be {@code null} if wrapping format is unsupported
   */
  [unwrapData](dataWrapper, queryId) {
    if (dataWrapper && dataWrapper.successful === true) {
      // get.path: /search?
      if (_.has(dataWrapper, 'data.data.items')) {
        return this[buildResult](dataWrapper, dataWrapper.data.data.items);
      }
      // get.path /ui/quilts
      if (_.has(dataWrapper, 'data.data')) {
        return this[buildResult](dataWrapper, dataWrapper.data.data);
      }
      if (_.has(dataWrapper, 'data.items')) {
        return this[buildResult](dataWrapper, dataWrapper.data.items);
      }
      if (_.has(dataWrapper, 'response.items')) {
        return this[buildResult](dataWrapper, dataWrapper.response.items);
      }
      if (_.has(dataWrapper, 'data.count')) {
        return this[buildResult](dataWrapper, dataWrapper.data.count);
      }
      if (_.has(dataWrapper, 'response.count')) {
        return this[buildResult](dataWrapper, dataWrapper.response.count);
      }
      if (_.has(dataWrapper, 'data')) {
        return this[buildResult](dataWrapper, dataWrapper.data);
      }
      if (_.has(dataWrapper, 'response')) {
        return this[buildResult](dataWrapper, dataWrapper.response);
      }
    } else {
      log.error(
        `Prefetch query unsuccessful for component [${this.alias}], query [${queryId}]`,
        dataWrapper && dataWrapper.error,
        dataWrapper
      );
    }
    return { meta: {}, data: null };
  }

  /**
   * Builds up a full result object with data, optional meta(data)
   */
  /* eslint class-methods-use-this: "off" */
  [buildResult](dataWrapper, obj) {
    const meta = {};
    // if we have metadata, build it up
    if (_.has(dataWrapper, 'count')) {
      Object.assign(meta, { count: dataWrapper.count });
    } else if (_.has(dataWrapper, 'data.count')) {
      Object.assign(meta, { count: dataWrapper.data.count });
    } else if (_.has(dataWrapper, 'response.count')) {
      Object.assign(meta, { count: dataWrapper.response.count });
    }
    if (_.has(dataWrapper, 'next_cursor')) {
      Object.assign(meta, { nextCursor: dataWrapper.next });
    } else if (_.has(dataWrapper, 'data.next_cursor')) {
      Object.assign(meta, { nextCursor: dataWrapper.data.next_cursor });
    } else if (_.has(dataWrapper, 'response.next_cursor')) {
      Object.assign(meta, { nextCursor: dataWrapper.response.next_cursor });
    }
    return { meta, data: obj };
  }

  /**
   * Adds the call for the specified query ID to a queue. The queue
   * attempts to wait for a single tick to go by before performing the
   * request to fetch the data. This allows for multiple queries to be
   * batched in a single call to get the data or if needed to make multiple calls.
   *
   * @param {string} queryId the query ID to add to the fetch queue
   * @param {object} call the call associated with the query, or null if not specified
   */
  /* eslint no-param-reassign: "off" */
  [addQueryToFetchQueue](queryId, call) {
    // Hack until DougS fixes server side to not return "collection" in the "call"
    // This only works for top image contribs component until fixed
    if (_.has(call, 'query.users.subQueries.public_images.collection')) {
      delete call.query.users.subQueries.public_images.collection;
    }

    if (!Object.prototype.hasOwnProperty.call(this[callFetchQueue], queryId)) {
      this[callFetchQueue][queryId] = call;

      if (Object.keys(this[callFetchQueue]).length === 1) {
        // We just added the first one
        // queue jobs within the same tick to allow for
        // calls to be batched in one request
        setTimeout(() => {
          const calls = [];
          const queryIds = [];

          _.forEach(this[callFetchQueue], (value, key) => {
            calls.push(value);
            queryIds.push(key);
          });

          const event = document.createEvent('CustomEvent');
          event.initCustomEvent('LITHIUM:partialRenderProxy', true, true, {
            calls,
            success: response => this[fetchDataSuccess](response, queryIds, calls)
          });
          document.dispatchEvent(event);
          this[callFetchQueue] = {};
        });
      }
    }
  }

  /**
   * Success handler for fetching deferred data
   *
   * @param {object} response the REST response
   * @param {array} queryIds list of query IDs whose data was fetched
   * @param {array} calls list of calls which were executed
   */
  [fetchDataSuccess](response, queryIds, calls) {
    queryIds.forEach((queryId, i) => {
      const resultData = queryIds.length === 1 ? response.data : response.data[i];
      const callKey = this[buildCacheKey](queryId, calls[i]);
      this[queryDataMap].set(callKey, this[unwrapData](resultData, queryId));
      const loadedData = this[queryDataMap].get(callKey);
      const dataLoadedPromiseAndResolver = this[dataLoadedPromises][callKey];
      if (loadedData !== undefined && dataLoadedPromiseAndResolver) {
        dataLoadedPromiseAndResolver.resolver(loadedData);
      }
    });
  }

  /**
   * Failure handler for fetching deferred data
   *
   * @param {object} error the REST error
   * @param {array} queryIds list of query IDs whose data was fetched
   * @param {array} calls list of calls which were executed
   */
  [fetchDataFailure](error, queryIds, calls) {
    log.warn('Failed to fetch data: ', error);
    queryIds.forEach((queryId, i) => {
      const callKey = this[buildCacheKey](queryId, calls[i]);
      const dataLoadedPromiseAndResolver = this[dataLoadedPromises][callKey];
      if (error !== undefined && dataLoadedPromiseAndResolver) {
        dataLoadedPromiseAndResolver.rejector(error);
      }
    });
  }

  /**
   * Get data from the prefetch, for queries that use the {@code defer} flag, a call
   * to this method will have the side-effect of triggering a fetch to get the data
   * and set it on the  object once received.
   *
   * @param {string} queryId
   * @param {boolean} forceFetch
   * @returns {object} an object around the data for the specific query,
   * can be {@code null} if the queryId does not exist or the data is deferred and has not loaded yet.
   */
  getData(queryId, forceFetch) {
    const call = this.getCall(queryId);
    const result = this.getResult(queryId, call, forceFetch);
    return result ? result.data : null;
  }

  /**
   * Get full result from the prefetch for a given call, for queries that use the {@code defer} flag, a call
   * to this method will have the side-effect of triggering a fetch to get the data and set object once received,
   *
   * @param {string} queryId
   * @param {object} call
   * @param {boolean} forceFetch

   * @returns {object} an object around the data for the specific query,
   * can be {@code null} if the queryId does not exist or the data is deferred and has not loaded yet.
   */
  getResult(queryId, call, forceFetch) {
    const callKey = this[buildCacheKey](queryId, call);
    if (this[queryDataMap].get(callKey) === undefined || forceFetch) {
      this[queryDataMap].set(callKey, null);
      const queryContainer = _.get(this[data], queryId, null);
      if (queryContainer !== null) {
        // if the call is null or the same as the original call, use the prefetch result
        if (
          queryContainer.result !== undefined &&
          (call == null || callKey === this[buildCacheKey](queryId, queryContainer.call))
        ) {
          this[queryDataMap].set(callKey, this[unwrapData](queryContainer.result, queryId));
        } else if (call && RestManager.canCall()) {
          this[addQueryToFetchQueue](queryId, call);
        } else {
          log.warn(
            `No result for query '${queryId}' and no call defined in prefetch (or we're trying to resolve deferred data on the server).`
          );
        }
      }
    }

    return this[queryDataMap].get(callKey);
  }

  /**
   * Gets the specified call object, requires the {@code storeCall} property to be set
   * in the prefetch.json for the referenced query.
   *
   * @param {string} queryId the query ID
   * @returns {*} the call object or {@code null} when it does not exist.
   */
  getCall(queryId) {
    const query = _.get(this[data], [queryId, 'call'], null);

    if (query === null) {
      log.trace(`No prefetch call for component [${this.alias}] and query [${queryId}]`);
      return null;
    }

    return _.cloneDeep(query);
  }

  /**
   * Gets the specified query object, requires the {@code storeCall} property to be set
   * in the prefetch.json for the referenced query.
   *
   * @param {string} queryId the query ID
   * @returns {*} the query object or {@code null} when it does not exist.
   */
  getQuery(queryId) {
    const { query } = this.getCall(queryId);

    if (query === null) {
      log.trace(`No prefetch query for component [${this.alias}] and query [${queryId}]`);
      return null;
    }

    return _.cloneDeep(query);
  }

  /**
   * Gets the specified sub-query object, requires the {@code storeCall} property to be set
   * in the prefetch.json for the referenced query.
   *
   * @param {string} queryId the query ID
   * @param {string} subQueryId the sub-query ID
   * @param {string} collection the REST collection the neste data belongs to
   * @returns {*} the sub-query object or {@code null} when it does not exist.
   */
  getSubQuery(queryId, subQueryId, collection) {
    const query = this.getQuery(queryId);
    const queryValue = _.values(query)[0]; // assumed to be the first value from object
    const subQueryPartial = _.get(queryValue, ['subQueries', subQueryId], null);

    if (subQueryPartial === null) {
      log.warn(`No prefetch sub-query for query [${queryId}] and sub-query [${subQueryId}]`);
      return null;
    }

    const subQuery = {};
    subQuery[collection] = _.cloneDeep(subQueryPartial);
    return subQuery;
  }

  /**
   * @returns {boolean} whether all the prefetch data has loaded
   */
  isDataLoaded() {
    const ids = this.getDataQueryIds();
    for (let i = 0; i < ids.length; i++) {
      if (!this.isDataLoadedForQuery(ids[i])) {
        return false;
      }
    }
    return true;
  }

  /**
   * @param {String} queryId the query id
   * @returns {Boolean} whether the data is loaded for the query id
   */
  isDataLoadedForQuery(queryId) {
    return this.isDataLoadedForCall(queryId, this.getCall(queryId));
  }

  /**
   * @param {String} queryId the query id
   * @param {String} call the call
   * @returns {Boolean} whether the data is loaded for the query id and call
   */
  isDataLoadedForCall(queryId, call) {
    const cacheKey = this[buildCacheKey](queryId, call);
    const loadedData = this[queryDataMap].get(cacheKey);

    if (loadedData === null || loadedData === undefined) {
      return _.get(this[data], [queryId, 'result'], null) !== null;
    }

    return true;
  }

  /**
   * @returns {Array} an array of the prefetch query IDs
   */
  getDataQueryIds() {
    return this[data] ? Object.keys(this[data]) : [];
  }

  /**
   * Gets a Promise that will resolve when the data for the specified query is available.
   *
   * @param {String} queryId the query id to register a callback for
   * @param {boolean} forceFetch
   * @return {Promise} a promise for the resolved data
   */
  waitForData(queryId, forceFetch) {
    return this.waitForResult(queryId, this.getCall(queryId), forceFetch).then(
      result => result.data || result
    );
  }

  /**
   * Gets a Promise that will resolve when the result for the specified call is available.
   *
   * @param {String} queryId the query id to register a callback for
   * @param {String} call the call to execute (optional)
   * @param {boolean} forceFetch
   * @return {Promise} a promise for the resolved result
   */
  waitForResult(queryId, call, forceFetch) {
    const result = this.getResult(queryId, call, forceFetch);
    if (result) {
      return Promise.resolve(result);
    } else {
      const callKey = this[buildCacheKey](queryId, call);
      let promiseAndResolver = this[dataLoadedPromises][callKey];
      if (!promiseAndResolver) {
        promiseAndResolver = {};
        promiseAndResolver.promise = new Promise((resolve, reject) => {
          promiseAndResolver.resolver = resolve;
          promiseAndResolver.rejector = reject;
        });
        this[dataLoadedPromises][callKey] = promiseAndResolver;
      }
      return promiseAndResolver.promise;
    }
  }
}
