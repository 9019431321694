/* eslint no-unused-vars: "off" */
/* global window document */

'use strict';

import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import { log } from 'limuirs-utils';
import { getFQPath, parsePath } from 'limuirs-components';
import ApplicationGlobals from '../models/ApplicationGlobals';
import PrefetchHelper from '../utils/PrefetchHelper';
import LimuirsContext from '../../components/LimuirsContext';

const ATTR_LOADED = 'data-lia-limuirs-hydrated';

/* eslint no-param-reassign: "off", no-multi-assign: "off" */
export default async function loadComponents(loader) {
  async function loadComponentFactory(fqPath, release, cache, model) {
    log.debug(`Loading component factory for fqPath: ${fqPath}`);
    let cacheElement = cache[fqPath];
    if (!cacheElement) {
      const pathObj = parsePath(fqPath, release);
      const comp = await loader(pathObj);
      const Component = React.createFactory(comp.default || comp);
      if (Component) {
        Component.getPrefetchView = model.getPrefetchViewForComponent.bind(model);
        Component.req = model;
        cache[fqPath] = cacheElement = { pathObj, comp, Component };
      }
      log.debug(`Caching component factory for path ${fqPath}`);
    } else {
      log.debug(`Using cached component factory for path ${fqPath}`);
    }
    return cacheElement;
  }

  function getFactoryFromPath(cache, release, model) {
    return (path, instanceNo, prefetchOverride) => {
      const fqPath = getFQPath(instanceNo, path);
      const cacheObj = cache[fqPath];
      const { pathObj, Component } = cacheObj;
      if (prefetchOverride != null) {
        Component.getPrefetchView = ({ alias, instance }) =>
          new PrefetchHelper(prefetchOverride, alias, instance);
      }
      Component.req = model;
      return Component;
    };
  }

  async function loadComponent(node, release, cache, model) {
    // log.debug('Loading component at', node);
    const props = JSON.parse(node.getAttribute('data-lia-limuirs-comp'));
    // log.debug(context);
    if (props && props.fqPath) {
      const compPath = props.fqPath;
      // log.debug(`Loading component ${compPath}`);
      const { pathObj, comp, Component } = await loadComponentFactory(
        compPath,
        release,
        cache,
        model
      );
      if (comp !== null) {
        const ctx = {
          prefetch: model.getPrefetchViewForComponent(props),
          globals: model.globals,
          getFactory: getFactoryFromPath(cache, release, model),
          getService: (name, svcLoader) => model.getService(name, svcLoader),
          req: model
        };
        ReactDOM.hydrate(
          <LimuirsContext.Provider value={ctx}>{Component(props)}</LimuirsContext.Provider>,
          node
        );
        // in dev mode we need to hydrate inert components so HMR works as expected
        if (!comp.inert) {
          log.trace(`Loaded react component at path: ${compPath}`);
        } else {
          log.trace(`Loaded inert component at path: ${compPath}`);
        }
      } else {
        log.warn(`No component found at path: ${compPath}`);
      }
    } else {
      // in prod mode we don't hydrate inert components to reduce page weight
      log.debug('Component is inert', node);
    }
    // mark component as "loaded"
    node.setAttribute(ATTR_LOADED, 'true');
  }

  log.info('Loading LIMUIRS React components');
  const model = new ApplicationGlobals(window);
  const nodes = window.document.querySelectorAll(`.lia-limuirs-comp:not([${ATTR_LOADED}])`);
  const preloadPaths = _.get(window, 'LITHIUM.Limuirs.preloadPaths', []);
  const release = _.get(window, 'LITHIUM.release', 'active');
  let cache = {};
  await Promise.all(
    _.map(preloadPaths, async fqPath => {
      await loadComponentFactory(fqPath, release, cache, model);
    })
  );
  await Promise.all(
    _.map(nodes, async node => {
      await loadComponent(node, release, cache, model);
    })
  );
  cache = null;
  log.info('Loaded LIMUIRS React components');
}
