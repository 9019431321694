import React from 'react';
import _ from 'lodash';

/**
 * A global context that is expected to contain the following values:
 *
 * prefetch: prefetch data scoped to the component instance
 * globals: LIA page level data
 * text: text keys scoped to the component alias
 * getService: a function returning a request-scoped singleton (i.e. shared store instance)
 * getFactory: a function returning a React factory to create instances of nested dynamic components
 *
 */
const LimuirsContext = React.createContext();
const CONTEXT_PROPS = ['prefetch', 'globals', 'getService', 'getFactory', 'req'];

function hasContextProps(props) {
  for (let i = 0; i < CONTEXT_PROPS.length; i++) {
    if (!_.has(props, CONTEXT_PROPS[i])) {
      return false;
    }
  }
  return true;
}

function contextProps(props) {
  return _.pick(props, CONTEXT_PROPS);
}

export { hasContextProps, contextProps };

export default LimuirsContext;
