import _ from 'lodash';
import loadComponents from './client/loadComponents';
import loader from './client/loader';

(w => {
  const loadedFlag = 'LITHIUM.Limuirs.loaded';

  w.LITHIUM.Loader.onLoad(async () => {
    if (!_.get(window, loadedFlag, false)) {
      _.set(window, loadedFlag, true);
      // eslint-disable-next-line promise/catch-or-return
      await loadComponents(loader);
    }
  });

  // allow rerender of newly added components to the DOM on event
  w.addEventListener('LITHIUM:LimuirsRerender', () => loadComponents(loader));
})(window);
