/* global LITHIUM */
import pino from 'pino';
import { canUseDOM } from 'exenv';
import _ from 'lodash';
import fs from 'fs';
import os from 'os';

import configs from '../../release-config.json';

const devMode = process.env.NODE_ENV !== 'production'; // NODE_ENV provided by DefinePlugin in Webpack config
const LOG_LEVEL_PROP = 'Limuirs.logLevel';

function getDestination(name) {
  if (!canUseDOM && !devMode) {
    const logDir = fs.existsSync(process.env.LOG_DIR)
      ? process.env.LOG_DIR
      : `${process.cwd()}/logs`;
    const dest = pino.destination(`${logDir}/${name}.log`);
    process.on('SIGHUP', () => dest.reopen());
    return dest;
  }
  return null;
}

function getLogLevelFromClient() {
  return (
    (typeof URLSearchParams !== 'undefined' &&
      new URLSearchParams(window.location.search).get(LOG_LEVEL_PROP)) ||
    (typeof LITHIUM !== 'undefined' && _.get(LITHIUM, LOG_LEVEL_PROP))
  );
}

function getLogLevel(key) {
  return (
    (canUseDOM && getLogLevelFromClient()) ||
    process.env[`LOG_LEVEL_${key.toUpperCase()}`] ||
    (devMode ? 'debug' : 'error')
  );
}

function getBase(hostname) {
  const { revision, release } = configs[0];
  return !devMode
    ? {
        base: {
          pid: process.pid,
          hostname,
          revision,
          release
        }
      }
    : {};
}

function wrapTraceInfo(pinoInstance) {
  /* eslint global-require: "off" */
  return !canUseDOM && !devMode
    ? require('../utils/traceInfo').wrapLogger(pinoInstance)
    : pinoInstance;
}

function buildLog(name) {
  const hostname = os.hostname();
  const base = getBase(hostname);
  const pinoOpts = Object.assign(
    {
      name,
      prettyPrint: devMode,
      browser: { asObject: !devMode },
      level: getLogLevel(name)
    },
    base
  );
  const destination = getDestination(name);
  return Object.assign(wrapTraceInfo(pino(pinoOpts, destination)), {
    logName: name,
    hostname,
    destination
  });
}

// NOTE: the per-log environment variables are defined in .development.env and .production.env
const applicationLog = buildLog('application');
const serverLog = buildLog('server');

export { applicationLog, serverLog };
