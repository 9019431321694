import _ from 'lodash';
import { log } from 'limuirs-utils';

/**
 * Provides localization text for a given component.
 */
export default class TextHelper {
  liTextPathParts = [];

  constructor(textData, path, showTextKeys) {
    /*
     * Derive the canonical text key format for a component
     * from the component path.
     *
     * component path format: components/<feature>/<component>
     * component text key prefix format: li.<feature>.<component>
     */
    if (path) {
      this.liTextPathParts = path.replace('components/', 'li/').split('/');
      if (_.has(textData, this.liTextPathParts)) {
        this.data = _.get(textData, this.liTextPathParts, {});
      } else {
        this.data = {};
        log.trace(`No text keys registered for component path: ${path}`);
      }
    } else {
      log.trace('No text key path specified.');
      this.data = {};
    }
    this.showTextKeys = showTextKeys;
  }

  /**
   * Formats a text key with the provided context
   *
   * @param {String} key the text key
   * @param {Object} context map of context objects that will be provided to text key when compiled
   * @returns {String} the formatted text key for a given language
   */
  format(key, context) {
    if (_.has(this.data, key)) {
      if (this.showTextKeys) {
        return `${this.liTextPathParts.join('.')}.${key}`;
      }
      const textFn = _.get(this.data, key);
      return _.isFunction(textFn) ? textFn(context) : textFn;
    }
    log.warn(`No text value registered for key: ${this.liTextPathParts.join('.')}.${key}`);

    return null;
  }
}
