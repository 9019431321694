import { log } from 'limuirs-utils';
import _ from 'lodash';

const MODULE_PREFIX = 'components/';

/* eslint prefer-destructuring: "off" */
function parsePath(path, release = 'active') {
  try {
    const split = path.split('/');
    const instanceNo = split[0];
    let pkgName = split[1];
    const componentsDirIndex = split.indexOf('components');
    if (componentsDirIndex === -1) {
      log.error(`No '/components' directory found in component path: ${path}`);
      return null;
    }
    // handle 'sample' and any other special cased packages
    if (pkgName === 'limuirs' && componentsDirIndex > 2) {
      pkgName = split[2];
    }
    const compPath = split.slice(componentsDirIndex + 1).join('/');
    log.trace(
      `Path '${path}' parsed as ${JSON.stringify({
        instanceNo,
        pkgName,
        release,
        compPath
      })}`
    );
    return { instanceNo, pkgName, release, compPath };
  } catch (error) {
    log.error(`Error parsing component path: ${path}`, error);
    return null;
  }
}

function pathFromModuleId(moduleId) {
  if (moduleId) {
    const split = _.split(moduleId, MODULE_PREFIX, 2);
    if (split.length < 2) {
      throw new Error(`Invalid module id: ${moduleId}`);
    }
    return `${MODULE_PREFIX}${split[1].replace('/index', '').replace(/.jsx?/, '')}`;
  }
  throw new Error('Missing module id');
}

function getFQPath(instance, path) {
  return [instance, path].join('/');
}

export { parsePath, getFQPath, pathFromModuleId };
