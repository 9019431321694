import { log } from 'limuirs-utils';

export default function(pathObj) {
  const { pkgName, compPath } = pathObj;
  if (pkgName === 'limuirs') {
    return import(
      /* webpackInclude: /components\/*.*\.jsx$/ */
      /* webpackChunkName: "[request]" */
      `../../components/${compPath}`
    );
  }
  if (pkgName === 'sample') {
    return import(
      /* webpackInclude: /components\/*.*\.jsx$/ */
      /* webpackChunkName: "sample-[request]" */
      `../../sample/components/${compPath}`
    );
  }
  log.error(`Unsupported component path "${JSON.stringify(pathObj)}"`);
  return null;
}
